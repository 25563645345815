<template>
  <section>
    <b-tabs v-model="tabIndex">

      <b-tab title="Demanda">
        <demanda-list ref="historico-demanda"></demanda-list>
      </b-tab>


      <b-tab title="Ingreso Nueva Demanda">
        <nueva-demanda-form @nueva-demanda-agregada="refreshHistoricoDemanda"></nueva-demanda-form>
      </b-tab>
    </b-tabs>
  </section>
</template>
<script>
import
{
  BRow,
  BCol,
  BCard,
  BButton,
  BCardHeader,
  BFormFile,
  BCardTitle,
  BCardSubTitle,
  BCardText,
  BCardBody,
  BFormSelect,
  BIconNodeMinusFill,
  BTabs,
  BSpinner,
  BAlert,
  BTab,
  BLink,
  VBPopover,
  VBTooltip,
  BFormGroup,
  BFormInvalidFeedback

} from "bootstrap-vue";



import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'

import NuevaDemandaForm from "./NuevaDemandaForm.vue";
import DemandaList from "./DemandaList.vue";


export default {
  data()
  {
    let activeTabName = this.$route.params.active_tab_name;
        let tabIndex = 0;

        if (activeTabName == "Demanda") {
            tabIndex = 0;
        }

        if (activeTabName == "Ingreso Nueva Demanda") {
            tabIndex = 1;
        }

        return {
            tabIndex: tabIndex,
        };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardHeader,
    BFormFile,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BFormSelect,
    BTabs,
    VBPopover,
    VBTooltip,
    BTab,
    BLink,
    BAlert,
    BSpinner,
    //GoodTableBasic,
    BFormInvalidFeedback,
    BFormGroup,
    AgGridTable,


    NuevaDemandaForm,
    DemandaList
  },

  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
  },

  mounted()
  {

  },

  methods: {


    refreshHistoricoDemanda()
    {

      this.$refs['historico-demanda'].refreshRows()

    }



  },
};
</script>

<style scoped>
.carga-data {
  margin-top: 4%;
}
</style>

