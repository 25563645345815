<template>
  <section>
    <BrunaForm
      ref="nuevaDemandaRef"
      v-bind:fields="this.nueva_demanda.fields"
      :card="this.nueva_demanda.card"
      @change="nuevoSkuChange"
    ></BrunaForm>

    <b-card>
      <b-card-body>
        <b-button
          variant="primary"
          class="mt-4 float-right"
          @click="save"
          size="lg"
          >Guardar</b-button
        >
      </b-card-body>
    </b-card>
  </section>
</template>

<script>

import { VueAutosuggest } from 'vue-autosuggest'

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import BrunaForm from "@/views/brunacomponents/BrunaForm.vue";


import
{
  BRow,
  BCol,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BCard,
  BCardBody,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormDatepicker,
  BCardTitle,
  BCardSubTitle,
  VBTooltip,
  VBPopover
} from "bootstrap-vue";
import vSelect from 'vue-select';
import axios from "@axios";


import useApiServices from '@/services/useApiServices.js';

export default {

  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
  },

  props: {
    forecast: {
      type: Object,
      default: () => null
    }
  },

  data()
  {



    return {



      nueva_demanda: {
        card: {
          title: "Crear Nueva Demanda",
          subtitle: "",
          tooltip: ""
        },
        fields: [

          {
            label: "Mes DDA",
            type: "input",
            id: 'mes_demanda',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null,
            show: true
          },

          {
            label: "Año DDA",
            type: "input",
            id: 'annio_demanda',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null,
            show: true
          },          

          {
            label: "SKU",
            type: "select",
            id: 'sku',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opción" },
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

          {
            label: "Nombre",
            type: "input",
            id: 'name',
            rules: 'required',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null,
            show: true,
            disabled: true
          },





          {
            label: "Formato",
            type: "input",
            id: 'formato',
            rules: 'required',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null,
            disabled: true

          },

          {
            label: "Precio de Venta por Producto (SKU) S/./m2",
            type: "input",
            id: 'precio_de_venta_por_producto_sku',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null

          },

          /*

          {
            label: "Restricción Venta Mínimo m2",
            type: "input",
            id: 'restriccion_venta_minimo_m2',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null

          },

          {
            label: "Restricción Venta Máximo m2",
            type: "input",
            id: 'restriccion_venta_maximo_m2',
            rules: 'numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null

          },
*/
          {
            label: "Canal Tradicional M2",
            type: "input",
            id: 'canal_tradicional_m2',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 0

          },

          {
            label: "Canal Retail M2",
            type: "input",
            id: 'canal_retail_m2',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 0

          },

          {
            label: "Canal Corporativo M2",
            type: "input",
            id: 'canal_corporativo_m2',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 0

          },

          {
            label: "Canal Chile M2",
            type: "input",
            id: 'canal_chile_m2',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 0

          },

          {
            label: "Canal Ecuador/CAM M2",
            type: "input",
            id: 'canal_ecuador_cam_m2',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 0

          },          



          {
            label: "TOTAL M2",
            type: "input",
            id: 'total_m2',
            rules: 'required|numeric2',
            disabled: true,
            tooltip: null,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 0
          },






        ]
      },




    };
  },
  components: {
    BRow,
    BCol,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BCard,
    BCardBody,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormDatepicker,
    ToastificationContent,
    vSelect,
    BCardTitle,
    BCardSubTitle,
    VBPopover,
    VBTooltip,
    VueAutosuggest,
    BrunaForm,
  },

  created()
  {
    this.loadSkus()


  },

  mounted()
  {




  },


  methods: {

    findFieldByName(fields, name)
    {

      return fields.find(field => field.id == name)

    },


    loadSkus()
    {
      useApiServices.getSkus(this).then((response) =>
      {
        this.sku_options = [
          { value: null, text: "Selecciona una opción" }
        ]


        response.data.forEach(item =>
        {

            let value = item.meta_data
            value['id'] = item.id

          this.sku_options.push({
            value: value,
            text: item.meta_data['sku']
          })

        });


        this.findFieldByName(this.nueva_demanda.fields, 'sku').options = this.sku_options

      })
        .catch((error) =>
        {
          console.log(error);

        });



    },



    formatData()
    {

      return {
        nueva_demanda: this.nueva_demanda.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {}),
      }


    },


    createNuevaDemanda()
    {



      useApiServices.postOrderCreate(this.formatData(), this)

        .then((response) =>
        {

          this.$emit('nueva-demanda-agregada');

          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Carga Exitosa`,
              icon: "UploadIcon",
              variant: "success",
              html: `El nuevo contrato se ha creado exitosamente.`,
            },
          });


        })
        .catch((error) =>
        {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Ocurrio un Error`,
              icon: "UploadIcon",
              variant: "danger",
              html: `Ha ocurrido un error al crear el nuevo contrato</b>.`,
            },
          });

        });





    },


    save()
    {

      //console.log(JSON.stringify(this.formatData(), null, 2))

      let self = this;

      Promise.all([
        this.$refs.nuevaDemandaRef.validate(),


      ]).then(([nuevoDemandaValid]) =>
      {

        if (nuevoDemandaValid)
        {
          self.createNuevaDemanda()
        }


      })


    },



    findFieldByName(fields, name)
    {

      return fields.find(field => field.id == name)

    },





    nuevoSkuChange(field)
    {
        console.log("nuevoSkuChange", {field})

        if(field.id == "sku")
        {
            this.findFieldByName(this.nueva_demanda.fields, 'name').value = field.value.producto_o_sku
            this.findFieldByName(this.nueva_demanda.fields, 'formato').value = field.value.formato

        }


        let canal_tradicional_m2 = this.findFieldByName(this.nueva_demanda.fields, 'canal_tradicional_m2').value
        let canal_retail_m2 = this.findFieldByName(this.nueva_demanda.fields, 'canal_retail_m2').value
        let canal_corporativo_m2 = this.findFieldByName(this.nueva_demanda.fields, 'canal_corporativo_m2').value
        let canal_chile_m2 = this.findFieldByName(this.nueva_demanda.fields, 'canal_chile_m2').value
        let canal_ecuador_cam_m2 = this.findFieldByName(this.nueva_demanda.fields, 'canal_ecuador_cam_m2').value


        this.findFieldByName(this.nueva_demanda.fields, 'total_m2').value = (canal_tradicional_m2 + canal_retail_m2 + canal_corporativo_m2 + canal_chile_m2 + canal_ecuador_cam_m2)


    },


  },
};
</script>

<style scoped>
.titulo {
  margin-top: 5%;
}
</style>

<style lang="scss">
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";
</style>